// Generated by Framer (78a4586)

import { addFonts, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./qncs9RUQh-0.js";

const cycleOrder = ["WXflgNhRl"];

const serializationHash = "framer-7myLl"

const variantClassNames = {WXflgNhRl: "framer-v-1qcn0cl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {UqrkdNuY1: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, FhooqoejCfnxizfKUE, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "WXflgNhRl", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://www.instagram.com/reel/C7fFC07uUkU/?igsh=ZXZyMmVocGE5dXhx"} openInNewTab smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1qcn0cl", className, classNames)} framer-1tjlyae`} data-framer-name={"Buttton hero section"} layoutDependency={layoutDependency} layoutId={"WXflgNhRl"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-8c97877a-d236-41d0-87e1-aac0d0c12801, rgb(112, 69, 255))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}}><RichText __fromCanvasComponent children={getLocalizedValue("v0", activeLocale) ?? <React.Fragment><motion.p style={{"--font-selector": "R0Y7V29yayBTYW5zLTcwMA==", "--framer-font-family": "\"Work Sans\", \"Work Sans Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Assistir</motion.p></React.Fragment>} className={"framer-14fdoxm"} data-framer-name={"Manifesto Scoder"} fonts={["GF;Work Sans-700"]} layoutDependency={layoutDependency} layoutId={"FDaHqXq5H"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={FhooqoejCfnxizfKUE} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7myLl.framer-1tjlyae, .framer-7myLl .framer-1tjlyae { display: block; }", ".framer-7myLl.framer-1qcn0cl { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 49px; justify-content: center; overflow: visible; padding: 15px; position: relative; text-decoration: none; width: 302px; }", ".framer-7myLl .framer-14fdoxm { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7myLl.framer-1qcn0cl { gap: 0px; } .framer-7myLl.framer-1qcn0cl > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-7myLl.framer-1qcn0cl > :first-child { margin-left: 0px; } .framer-7myLl.framer-1qcn0cl > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 302
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerqncs9RUQh: React.ComponentType<Props> = withCSS(Component, css, "framer-7myLl") as typeof Component;
export default Framerqncs9RUQh;

Framerqncs9RUQh.displayName = "Button main home";

Framerqncs9RUQh.defaultProps = {height: 49, width: 302};

addFonts(Framerqncs9RUQh, [{explicitInter: true, fonts: [{family: "Work Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNi0Dp6_cOyA.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})